html, body {
    overflow: auto;
}

div.DraftEditor-root {
    border: none;
    background-color: #f5f5f5;  /* Material grey[100] */
    color: black;
    overflow-y: auto;
    width: 100%;    
    line-height: 2em;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

@media screen and (min-width: 900px) {
    div.DraftEditor-root {        
        height: calc(100vh - 100px);
    }  
    .tippy-box {
        font-size: medium;
    }  
}

@media screen and (max-width: 899px) {
    div.DraftEditor-root {        
        height: calc(50vh);
    }
    .tippy-box {
        font-size: smaller;
    }
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    padding: 5px;
}   

div.public-DraftEditorPlaceholder-root {
    color: rgb(138, 138, 145);
    padding-top: 3px;
    padding-left: 3px;
    margin-bottom: -20px;
    font-size: larger;
}

/* Default blue tippy theme */
.tippy-box {
    background-color: #0185c7 !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal !important;
}    

.tippy-box a {
    text-decoration: none;
    color: white;
}

.tippy-box a:hover {
    text-decoration: underline;
    color: white;
}

.tippy-box[data-placement^='top']>.tippy-arrow::before {
    border-top-color: #0185c7 !important;
}

.tippy-box[data-placement^='bottom']>.tippy-arrow::before {
    border-bottom-color: #0185c7 !important;
}

.tippy-box[data-placement^='left']>.tippy-arrow::before {
    border-left-color: #0185c7 !important;
}

.tippy-box[data-placement^='right']>.tippy-arrow::before {
    border-right-color: #0185c7 !important;
}

/* Teal tippy theme for PRISM words */
.tippy-box[data-theme~='teal'] {
    background-color: #00695c !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal !important;
}

.tippy-box[data-theme~='teal'] a {
    text-decoration: none;
    color: white;
}

.tippy-box[data-theme~='teal'] a:hover {
    text-decoration: underline;
    color: white;
}

.tippy-box[data-theme~='teal'][data-placement^='top']>.tippy-arrow::before {
    border-top-color: #00695c !important;
}

.tippy-box[data-theme~='teal'][data-placement^='bottom']>.tippy-arrow::before {
    border-bottom-color: #00695c !important;
}

.tippy-box[data-theme~='teal'][data-placement^='left']>.tippy-arrow::before {
    border-left-color: #00695c !important;
}

.tippy-box[data-theme~='teal'][data-placement^='right']>.tippy-arrow::before {
    border-right-color: #00695c !important;
}